import React from "react"
import PropTypes from "prop-types"

import GlobalContext from "../context/GlobalContext"
import Layout from "../components/Layout/Layout"

import { setDataLayer } from "../helpers/dataLayerSetter"

import RecipeDetailPageHero from "../components/UI/RecipeDetailPageHero/RecipeDetailPageHero"
import RecipeSummary from "../components/UI/RecipeSummary/RecipeSummary"
import RecipeInfoBlock from "../components/UI/RecipeInfoBlock/RecipeInfoBlock"
import RecipeSimilarSlider from "../components/UI/RecipeSimilarSlider/RecipeSimilarSlider"
import { gTagScriptConsent } from "../components/Scripts/Gtm.Head"
import RecipeVideo from "../components/UI/RecipeVideo/RecipeVideo"

const RecipeDetailPage = ({ pageContext }) => {
    const { globalComponents, seoData, pageType, dataLayer, globalLabels, recipe, similarRecipes , pageLanguage } = pageContext
    setDataLayer(dataLayer, seoData, pageType)

    return (
        <GlobalContext labels={globalLabels} language={pageLanguage}>
            <Layout seoData={seoData} globalComponents={globalComponents}>
                <RecipeDetailPageHero id="RecipeDetailPageHero" recipe={recipe} />
                <RecipeSummary id="RecipeSummary" recipe={recipe} />
                <RecipeVideo recipe={recipe} />
                <RecipeInfoBlock id="RecipeInfoBlock" recipe={recipe} />
                {Array.isArray(similarRecipes) && similarRecipes.length > 0 && (
                    <RecipeSimilarSlider id="RecipeSimilarSlider" recipes={similarRecipes} />
                )}
            </Layout>
        </GlobalContext>
    )
}
export const Head = () => {
    const dataObject = typeof window !== "undefined" ? window?.gtmDataLayer : {}
    return (
        <>
            <script
                id="first-unique-id"
                type="text/javascript"
                dangerouslySetInnerHTML={{
                    __html: `var MdlzdataLayer = ${JSON.stringify(dataObject)};`,
                }}
            />
            <script async type="text/javascript" dangerouslySetInnerHTML={{ __html: gTagScriptConsent }} />
        </>
    )
}

RecipeDetailPage.propTypes = {
    pageContext: PropTypes.shape({
        globalComponents: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
        pageType: PropTypes.string,
        dataLayer: PropTypes.object,
        seoData: PropTypes.shape({
            title: PropTypes.string,
            description: PropTypes.string,
            ogTitle: PropTypes.string,
            ogDescription: PropTypes.string,
            ogType: PropTypes.string,
            twitterTitle: PropTypes.string,
            twitterDescription: PropTypes.string,
            canonicalLink: PropTypes.string,
            metaTitle: PropTypes.string,
            featuredImage: PropTypes.shape({
                url: PropTypes.string,
            }),
        }),
        globalLabels: PropTypes.object,
        recipe: PropTypes.object,
        similarRecipes: PropTypes.array,
        pageLanguage: PropTypes.string
    }),
}

export default RecipeDetailPage
